import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import Layout from '../../layout'
import { useSiteConfig } from '../hooks/useSiteConfig'

const LayoutPrismic = ({ path, pageContext, children }) => {
  const gatsbyConfig = useStaticQuery(
    graphql`
      query SiteMetaData {
        site {
          siteMetadata {
            title
            subtitle
            description
            author
            siteUrl
            image
          }
        }
      }
    `
  )

  const defaultMetadata = gatsbyConfig.site.siteMetadata || {}
  const siteConfig = useSiteConfig()
  const siteMetaData = siteConfig.metadata || {}

  // remove empty properties
  // https://stackoverflow.com/a/38340374
  Object.keys(siteMetaData).forEach(
    (key) => siteMetaData[key] === undefined && delete siteMetaData[key]
  )

  const site = {
    ...siteConfig,
    metadata: { ...defaultMetadata, ...siteMetaData },
  }

  const page = {
    title: pageContext.title,
    path,
    type: pageContext.type || 'website',
    transparentHeader: pageContext.transparentHeader,
    hideLogo: pageContext.hideLogo,
    hideSubscribe: pageContext.hideSubscribe,
    darkerBg: pageContext.darkerBg,
    image: pageContext.shareImage,
  }

  return (
    <Layout site={site} page={page}>
      {children}
    </Layout>
  )
}

LayoutPrismic.propTypes = {
  children: PropTypes.node,
  path: PropTypes.string,
  pageContext: PropTypes.object,
}

export default LayoutPrismic
