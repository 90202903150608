import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const SCRIPT_URL =
  'https://js.createsend1.com/javascript/copypastesubscribeformlogic.js'

const CampaignMonitorForm = ({
  id,
  submitLabel,
  hideName,
  emailInput,
  customStyles,
}) => {
  useEffect(() => {
    const script = document.createElement('script')
    script.src = SCRIPT_URL
    script.async = true
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <form
      className={classNames('js-cm-form', customStyles.form)}
      id="subForm"
      action="https://www.createsend.com/t/subscribeerror?description="
      method="post"
      data-id={id}
    >
      {!hideName && (
        <div className={customStyles.name}>
          <label htmlFor="fieldName" className={customStyles.label}>
            Name
          </label>
          <input
            aria-label="Name"
            id="fieldName"
            maxLength="200"
            name="cm-name"
            className={customStyles.nameInput}
          />
        </div>
      )}
      <div className={customStyles.email}>
        <label htmlFor="fieldEmail" className={customStyles.label}>
          {emailInput?.label || 'Email'}
        </label>
        <input
          autoComplete="Email"
          aria-label={emailInput?.label || 'Email'}
          className={classNames(
            'js-cm-email-input',
            'qa-input-email',
            customStyles.emailInput
          )}
          id="fieldEmail"
          maxLength="200"
          name={emailInput?.name}
          required
          type="email"
          placeholder={emailInput?.placeholder}
        />
      </div>
      <button type="submit" className={customStyles.submit}>
        {submitLabel}
      </button>
    </form>
  )
}

CampaignMonitorForm.defaultProps = {
  submitLabel: 'Subscribe',
  customStyles: {},
}

CampaignMonitorForm.propTypes = {
  hideName: PropTypes.bool,
  id: PropTypes.string,
  submitLabel: PropTypes.string,
  emailInput: PropTypes.object,
  customStyles: PropTypes.object,
}

export { CampaignMonitorForm }
