import React from 'react'
import PropTypes from 'prop-types'
import { Container } from '@/components/Container'
import { CampaignMonitorForm } from '@/components/CampaignMonitorForm'
import * as styles from './SubscribeFooter.module.scss'

const SubscribeFooter = ({ heading, submitLabel, formId, emailInput }) => {
  return (
    <div className={styles.SubscribeFooter}>
      <Container>
        <div className={styles.container}>
          <h3 className={styles.heading}>{heading}</h3>
          {formId && (
            <CampaignMonitorForm
              id={formId}
              submitLabel={submitLabel}
              hideName={true}
              emailInput={emailInput}
              customStyles={{
                form: styles.form,
                email: styles.email,
                emailInput: styles.emailInput,
                label: styles.label,
                submit: styles.submit,
              }}
            />
          )}
        </div>
      </Container>
    </div>
  )
}

SubscribeFooter.propTypes = {
  heading: PropTypes.node,
  submitLabel: PropTypes.string,
  formId: PropTypes.string,
  emailPlaceholder: PropTypes.string,
}

export { SubscribeFooter }
