import React from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import PropTypes from 'prop-types'

// This value should be synced with gatsby-browser.js
// More info:
// scotch.io/tutorials/animated-page-transitions-in-gatsby-websites#toc-final-thoughts
const fadeDuration = 0.5

const containerAni = {
  initial: {},
  enter: {},
  exit: {},
}

const contentAni = {
  initial: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
    transition: {
      fadeDuration,
      delay: fadeDuration + 0.25,
    },
  },
  exit: {
    opacity: 0,
    transition: { fadeDuration },
  },
}

const PageTransition = ({ children, path }) => {
  return (
    <AnimatePresence>
      <motion.div
        key={path}
        variants={containerAni}
        initial="initial"
        animate="enter"
        exit="exit"
      >
        <motion.div key={`${path}_content`} variants={contentAni}>
          {children}
        </motion.div>
      </motion.div>
    </AnimatePresence>
  )
}

PageTransition.propTypes = {
  children: PropTypes.node,
  path: PropTypes.string,
}

export { PageTransition }
