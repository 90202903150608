import React from 'react'
import { RichText } from 'prismic-reactjs'
import { linkResolver } from '@/prismic/helpers/linkResolver'
import { Link } from '@/components/Link'

export const getRichText = (data) => {
  if (data?.length && data[0].text?.length) {
    return (
      <RichText
        render={data}
        serializeHyperlink={(type, element, content) => {
          let url
          let download = false

          switch (element.data.link_type) {
            case 'Web':
              url = element.data.url
              break
            case 'Document':
              url = linkResolver(element.data)
              break
            case 'Media':
              url = element.data.url
              download = true
              break
            default:
          }

          return (
            <Link
              key={`${element.data.id}-${content}`}
              to={url}
              download={download}
            >
              {content}
            </Link>
          )
        }}
      />
    )
  }
  return undefined
}
