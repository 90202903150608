import slugify from 'slugify'

/**
 * Convert string to slug
 * @param {String} text
 * @param {Object} options
 */

export const getSlug = (text, options) => {
  if (typeof text === 'string') {
    return slugify(text, { lower: true, ...options })
  }

  return undefined
}
