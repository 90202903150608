// extracted by mini-css-extract-plugin
export var Header = "Header-module--Header--dL45E";
export var aniSpin = "Header-module--ani-spin--vsoXn";
export var bg = "Header-module--bg--O9UrC";
export var btnMenu = "Header-module--btnMenu--MwDn4";
export var container = "Header-module--container--0bfw7";
export var description = "Header-module--description--MTU+Q";
export var description__text = "Header-module--description__text--FdgWn";
export var logo = "Header-module--logo--7bOtT";
export var logo__container = "Header-module--logo__container--U2RPE";
export var logo__inner = "Header-module--logo__inner--DLUFa";
export var nav = "Header-module--nav--e5nQ0";
export var nav__activeLink = "Header-module--nav__activeLink--hGzmU";
export var nav__item = "Header-module--nav__item--tjRn2";
export var nav__link = "Header-module--nav__link--HOgYX";
export var nav__list = "Header-module--nav__list--O9mB7";
export var nav__list__inner = "Header-module--nav__list__inner--0h30M";