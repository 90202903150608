// link resolver
// requires: type, uid
// as the project grows, this function should be updated according to the routes.

exports.linkResolver = page => {
  // paths
  if (page.type === 'profile') {
    return `/people/${page.uid}`
  }

  if (page.type === 'project' || page.type === 'category') {
    return `/projects/${page.uid}`
  }

  if (page.type === 'blog_post') {
    return `/news/${page.uid}`
  }

  if (page.type === 'modular') {
    const path = `/${page.uid === 'home' ? '' : page.uid}`
    return path
  }

  // fallback route
  if (page.pageNumber >= 2) {
    return `/${page.uid}/${page.pageNumber}`
  }
  return `/${page.uid}`
}
