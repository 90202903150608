import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Link } from '@/components/Link'
import { Container } from '@/components/Container'
import * as styles from './Menu.module.scss'

const Menu = ({ nav, isOpen, onNavigate, onClickClose }) => {
  const handleClickLink = () => {
    if (typeof onNavigate === 'function') onNavigate()
  }
  const handleClickClose = () => {
    if (typeof onClickClose === 'function') onClickClose()
  }

  return (
    <div className={classNames(styles.Menu, { [styles.isOpen]: isOpen })}>
      <div className={styles.bg} />
      <div className={styles.inner}>
        <div className={styles.header}>
          <Container className={styles.header__container}>
            <button onClick={handleClickClose} className={styles.close}>
              Close
            </button>
          </Container>
        </div>
        <Container>
          {!!nav?.length && (
            <nav className={styles.nav}>
              <ul className={styles.nav__list}>
                {nav.map((item) => {
                  return (
                    <li className={styles.nav__item} key={item.id}>
                      <Link
                        onClick={handleClickLink}
                        to={item.url}
                        className={styles.nav__link}
                      >
                        {item.title}
                      </Link>
                    </li>
                  )
                })}
              </ul>
            </nav>
          )}
        </Container>
      </div>
    </div>
  )
}

Menu.propTypes = {
  nav: PropTypes.arrayOf(PropTypes.object),
  isOpen: PropTypes.bool,
  onNavigate: PropTypes.func,
  onClickClose: PropTypes.func,
}

export { Menu }
