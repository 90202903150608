import React from 'react'
import PropTypes from 'prop-types'
import { RichText } from '@/components/RichText'
import { Container } from '@/components/Container'
import * as styles from './Footer.module.scss'

const Footer = ({ columns, notesPrimary, notesSecondary }) => {
  return (
    <footer className={styles.Footer}>
      {!!columns?.length && (
        <Container>
          <div className={styles.container}>
            {columns.map((item) => {
              return (
                <div
                  key={item.id}
                  className={styles.column}
                  data-column-width={item.column_width}
                >
                  <RichText>{item.content}</RichText>
                </div>
              )
            })}
          </div>
        </Container>
      )}
      <Container>
        <div className={styles.secondary}>
          {notesPrimary && (
            <div className={styles.acknowledgement}>{notesPrimary}</div>
          )}
          <div className={styles.notes}>
            <div className={styles.copyright}>
              Copyright {new Date().getFullYear()}
            </div>
            {notesSecondary && notesSecondary}
          </div>
        </div>
      </Container>
    </footer>
  )
}

Footer.propTypes = {
  columns: PropTypes.array,
  notesPrimary: PropTypes.node,
  notesSecondary: PropTypes.node,
}

export { Footer }
