import { useStaticQuery, graphql } from 'gatsby'
import { getSlug } from '@/utils/getSlug'
import { getRichText } from '@/prismic/helpers/getRichText'

export const useSiteConfig = () => {
  const { prismicSiteConfig } = useStaticQuery(
    graphql`
      query SiteConfig {
        prismicSiteConfig {
          data {
            title
            short_description
            acknowledgment_statement {
              raw
            }
            meta_description
            meta_subtitle
            meta_image {
              url
            }
            nav {
              link
              title
            }
            footer_columns {
              content {
                raw
              }
              column_width
              align
            }
            footer_notes {
              raw
            }
            newsletter_heading {
              raw
            }
            newsletter_submit_label
          }
        }
      }
    `
  )

  const { data } = prismicSiteConfig

  const navArray = data.nav.map((item, index) => {
    return {
      id: `nav-${index}`,
      title: item.title,
      url: item.link,
    }
  })

  const footerColumns = data.footer_columns.map((item, index) => {
    return {
      id: `footer-column-${index}`,
      content: getRichText(item.content?.raw),
      align: getSlug(item.align),
      column_width: getSlug(item.column_width),
    }
  })

  const siteConfig = {
    metadata: {
      title: data.title,
      description: data.meta_description,
      subtitle: data.meta_subtitle,
      image: data.meta_image?.url,
    },
    header: {
      tagLine: data.short_description,
      nav: navArray,
    },
    footer: {
      columns: footerColumns,
      notes: getRichText(data.footer_notes?.raw),
      acknowledgmentStatement: getRichText(data.acknowledgment_statement?.raw),
    },
    subscribe: {
      heading: getRichText(data.newsletter_heading?.raw),
      formId:
        '191722FC90141D02184CB1B62AB3DC26D88028A396BC3720C169EA79F7F777E3221B1288274291E14F72E4B1AA7F3693A3F3793D8C986E094619745BBAF629F0',
      emailInput: {
        name: 'cm-jjjrydy-jjjrydy',
        label: 'Email',
        placeholder: 'Your email address',
      },
      submitLabel: data.newsletter_submit_label,
    },
  }

  return siteConfig
}
