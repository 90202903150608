// extracted by mini-css-extract-plugin
export var Menu = "Menu-module--Menu--HoqWs";
export var aniSpin = "Menu-module--ani-spin--Zmf5l";
export var bg = "Menu-module--bg--k23Oi";
export var close = "Menu-module--close--0ClVK";
export var header = "Menu-module--header--IWuP7";
export var header__container = "Menu-module--header__container--L6-3G";
export var inner = "Menu-module--inner--vm48w";
export var isOpen = "Menu-module--isOpen--68GKd";
export var nav = "Menu-module--nav--sYdR6";
export var nav__item = "Menu-module--nav__item--ZZrvm";
export var nav__link = "Menu-module--nav__link--QiCjV";
export var nav__list = "Menu-module--nav__list--0vKAn";