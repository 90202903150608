import React from 'react'
import PropTypes from 'prop-types'
import '@/styles/base.scss'
import { Metadata } from '@/components/Metadata'
import { Header } from '@/components/Header'
import { Footer } from '@/components/Footer'
import { SubscribeFooter } from '@/components/SubscribeFooter'
import { PageTransition } from '@/components/PageTransition'
import * as styles from './layout.module.scss'

const Layout = ({ site, page, children }) => {
  return (
    <div>
      {site.metadata && (
        <Metadata
          title={page.title}
          defaultTitle={
            site.metadata.subtitle
              ? `${site.metadata.title}: ${site.metadata.subtitle}`
              : site.metadata.title
          }
          titleTemplate={`%s | ${site.metadata.title}`}
          type={page.type}
          description={site.metadata.description}
          image={page.image || site.metadata.image}
          siteUrl={site.metadata.siteUrl}
          path={page.path}
        />
      )}
      <div
        className={styles.Layout}
        data-bg={page.darkerBg ? 'darker' : 'default'}
        data-transparent-header={page.transparentHeader}
      >
        {site.header && (
          <Header
            title={site.metadata?.title}
            tagLine={site.header?.tagLine}
            nav={site.header?.nav}
            path={page.path}
            transparent={page.transparentHeader}
            hideLogo={page.hideLogo}
          />
        )}
        <main className={styles.main}>
          <PageTransition path={page.path}>{children}</PageTransition>
        </main>
        {site.subscribe && !page.hideSubscribe && (
          <SubscribeFooter {...site.subscribe} />
        )}
        {!page.hideFooter && site.footer && (
          <Footer
            columns={site.footer.columns}
            notesPrimary={site.footer.acknowledgmentStatement}
            notesSecondary={site.footer.notes}
          />
        )}
      </div>
    </div>
  )
}

Layout.defaultProps = {
  site: {
    metadata: {},
    header: {},
    footer: {},
    newsletter: {},
  },
  page: {
    newsletterSubscribe: false,
    transparentHeader: false,
    darkerBg: false,
    hideLogo: false,
    hideFooter: false,
  },
}

Layout.propTypes = {
  site: PropTypes.object,
  page: PropTypes.object,
  children: PropTypes.node.isRequired,
}

export default Layout
