// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-preview-jsx": () => import("./../../../src/pages/preview.jsx" /* webpackChunkName: "component---src-pages-preview-jsx" */),
  "component---src-pages-preview-unpublished-jsx": () => import("./../../../src/pages/preview-unpublished.jsx" /* webpackChunkName: "component---src-pages-preview-unpublished-jsx" */),
  "component---src-prismic-types-basic-page-prismic-jsx": () => import("./../../../src/prismic/types/BasicPage.prismic.jsx" /* webpackChunkName: "component---src-prismic-types-basic-page-prismic-jsx" */),
  "component---src-prismic-types-blog-post-prismic-jsx": () => import("./../../../src/prismic/types/BlogPost.prismic.jsx" /* webpackChunkName: "component---src-prismic-types-blog-post-prismic-jsx" */),
  "component---src-prismic-types-blog-posts-prismic-jsx": () => import("./../../../src/prismic/types/BlogPosts.prismic.jsx" /* webpackChunkName: "component---src-prismic-types-blog-posts-prismic-jsx" */),
  "component---src-prismic-types-page-prismic-jsx": () => import("./../../../src/prismic/types/Page.prismic.jsx" /* webpackChunkName: "component---src-prismic-types-page-prismic-jsx" */),
  "component---src-prismic-types-profile-prismic-jsx": () => import("./../../../src/prismic/types/Profile.prismic.jsx" /* webpackChunkName: "component---src-prismic-types-profile-prismic-jsx" */),
  "component---src-prismic-types-project-prismic-jsx": () => import("./../../../src/prismic/types/Project.prismic.jsx" /* webpackChunkName: "component---src-prismic-types-project-prismic-jsx" */),
  "component---src-prismic-types-projects-prismic-jsx": () => import("./../../../src/prismic/types/Projects.prismic.jsx" /* webpackChunkName: "component---src-prismic-types-projects-prismic-jsx" */),
  "component---src-prismic-types-subscribe-prismic-jsx": () => import("./../../../src/prismic/types/Subscribe.prismic.jsx" /* webpackChunkName: "component---src-prismic-types-subscribe-prismic-jsx" */)
}

