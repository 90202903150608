import React from 'react'
import PropTypes from 'prop-types'
import * as styles from './BtnMenu.module.scss'

const BtnMenu = ({ onClick, isActive }) => {
  const toggle = () => {
    if (typeof onClick === 'function') onClick(!isActive)
  }

  return (
    <button
      type="button"
      className={styles.BtnMenu}
      aria-label="Toggle Menu"
      onClick={toggle}
      data-open={isActive}
    >
      <div className={styles.menu}>
        <div className={styles.menu__line} />
        <div className={styles.menu__line} />
        <div className={styles.menu__line} />
      </div>
      <div className={styles.close}>
        <div className={styles.close__line} />
        <div className={styles.close__line} />
      </div>
    </button>
  )
}

BtnMenu.propTypes = {
  onClick: PropTypes.func,
  isActive: PropTypes.bool,
}

export { BtnMenu }
