import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { motion, AnimatePresence } from 'framer-motion'
import LogoSVG from '@/images/logo-oculus.inline.svg'
import { Link } from '@/components/Link'
import { BtnMenu } from '@/components/BtnMenu'
import { Container } from '@/components/Container'
import { Menu } from '@/components/Menu'
import * as styles from './Header.module.scss'

const Header = ({ title, tagLine, nav, transparent, hideLogo }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const handleClickBtnMenu = (state) => setIsMenuOpen(state)
  const isPartiallyActive = ({ isPartiallyCurrent }) => {
    return isPartiallyCurrent ? { className: styles.nav__activeLink } : null
  }
  const NavLink = (props) => <Link getProps={isPartiallyActive} {...props} />
  const handleClickMenuLink = () => setIsMenuOpen(false)
  const handleClickCloseMenu = () => setIsMenuOpen(false)

  return (
    <>
      <header className={styles.Header} data-transparent={transparent}>
        <div className={styles.bg} />
        <Container>
          <div className={styles.container}>
            <AnimatePresence>
              {hideLogo ? (
                <div className={styles.description}>
                  <motion.div
                    key="header-description-text"
                    className={styles.description__text}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ ease: 'circOut', duration: 0.75 }}
                  >
                    {tagLine}
                  </motion.div>
                </div>
              ) : (
                <h1 className={styles.logo}>
                  <div className={styles.logo__container}>
                    <motion.div
                      key="header-logo-inner"
                      className={styles.logo__inner}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ ease: 'circOut', duration: 0.75 }}
                    >
                      <Link to="/" aria-label={title}>
                        <LogoSVG />
                      </Link>
                    </motion.div>
                  </div>
                </h1>
              )}
            </AnimatePresence>
            {!!nav?.length && (
              <AnimatePresence>
                <motion.nav
                  className={styles.nav}
                  key="header-nav"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ ease: 'circOut', duration: 0.75 }}
                >
                  <div className={styles.nav__list}>
                    <ul className={styles.nav__list__inner}>
                      {nav.map((item) => {
                        return (
                          <li className={styles.nav__item} key={item.id}>
                            <NavLink to={item.url} className={styles.nav__link}>
                              {item.title}
                            </NavLink>
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                </motion.nav>
              </AnimatePresence>
            )}
            <div className={styles.btnMenu}>
              <BtnMenu isActive={false} onClick={handleClickBtnMenu} />
            </div>
          </div>
        </Container>
      </header>
      <Menu
        isOpen={isMenuOpen}
        nav={nav}
        onNavigate={handleClickMenuLink}
        onClickClose={handleClickCloseMenu}
      />
    </>
  )
}

Header.propTypes = {
  title: PropTypes.string,
  tagLine: PropTypes.string,
  nav: PropTypes.arrayOf(PropTypes.object),
  transparent: PropTypes.bool,
  hideLogo: PropTypes.bool,
}

export { Header }
