import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import * as styles from './Container.module.scss'

const Container = ({ children, className, indent }) => (
  <div className={classNames(styles.Container, className)}>
    {indent ? (
      <div className={styles.indent} data-indent={indent}>
        {children}
      </div>
    ) : (
      children
    )}
  </div>
)

Container.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  indent: PropTypes.number,
}

export { Container }
